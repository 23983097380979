var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import * as _ from "lodash";
import { Button, Card, Checkbox, Form, Input, InputNumber, message, PageHeader, Radio, Select, Table, Upload, DatePicker, Tooltip, Switch, Tag } from "antd";
import { DeleteOutlined, PlusOutlined, UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { LabelEditor } from "../../component/LabelEditor/LabelEditor";
import { routerStore } from "../../../../store/routerStore";
import { labelTaskCreateStore } from "./LabelTaskCreateStore";
import { toJS, autorun } from "mobx";
import { observer } from "mobx-react";
import { IResource } from "../../../../definition/entity/resource";
import { SandPackageLabelModal } from "./component/SandPackageLabelModal/SandPackageLabelModal";
import { PolicyThreshold } from "./component/PolicyThreshold/PolicyThreshold";
import { randomString } from "util/random-string";
import "./LabelTaskCreate.less";
import MediaInfo from "mediainfo.js";
import MediaInfoHelper from "util/media-info-helper";
import { ILabelTask } from "definition/entity/label-task";
import moment from "moment";
import { labelEditorStore } from "page/manage/component/LabelEditor/LabelEditorStore";
import DataoceanDialog from "page/manage/component/TaskForm/DataoceanDialog";
import async from "async";
const TaskNameLenLimit = 100;
let LabelTaskCreate = class LabelTaskCreate extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
        this.dataoceanDialogRef = React.createRef();
        this.state = {
            resources: [],
            reserveMeta: true,
            reserveAnnotation: true,
            dataoceanDialogVisible: false,
            loading: false,
            blobGroup: []
        };
    }
    componentDidMount() {
        labelTaskCreateStore.loadTaskBusinesses();
        labelTaskCreateStore.loadTaskTemplates();
        labelTaskCreateStore.loadSandBags();
        labelTaskCreateStore.loadAssessmentPolicies();
        autorun(() => {
            if (labelEditorStore.status.segments.length) {
                labelTaskCreateStore.status.perPackageCount = 50;
            }
        });
    }
    componentWillUnmount() {
        labelTaskCreateStore.resetStatus();
    }
    async updateDroplets(blobs) {
        const status = labelTaskCreateStore.status;
        const res = await async.mapSeries(blobs, async (blob) => {
            const droplets = await this.dataoceanDialogRef.current.fetchDatasetContents(blob);
            const resources = droplets.dropletsList
                .filter(v => v.spec.file.locationsMap.length)
                .map(droplet => {
                var _a, _b, _c;
                let [key, bubbleLocation] = droplet.spec.file.locationsMap[0];
                const sinkName = key;
                const filepath = bubbleLocation.key.replace(/^\/+/, "");
                return {
                    url: `${sinkName}:///` + filepath,
                    meta: status.resourceMime === IResource.Mime.Video
                        ? {
                            duration: ((_a = droplet.spec.content) === null || _a === void 0 ? void 0 : _a.video.duration.seconds) || 0 / 1000,
                            frameRate: (_b = droplet.spec.content) === null || _b === void 0 ? void 0 : _b.video.frameRate.numerator,
                            videoCodecName: (_c = droplet.spec.content) === null || _c === void 0 ? void 0 : _c.video.codec,
                            startTime: 0
                        }
                        : {}
                };
            });
            return resources;
        });
        const resources = _.flatten(res);
        this.setState({
            resources
        });
        labelTaskCreateStore.chooseDataOceanBlob(resources);
    }
    render() {
        var _a;
        const status = labelTaskCreateStore.status;
        return (<PageHeader title="创建打标任务" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/label_tasks");
        }}>
        <Card>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} requiredMark={false}>
            <Form.Item label={<span className="label-required">任务名称</span>}>
              <Input placeholder="请输入任务名称" value={status.name} maxLength={TaskNameLenLimit} onChange={event => {
            status.name = event.target.value;
        }}/>
            </Form.Item>
            <Form.Item label="计价类型">
              <Select showSearch placeholder="请选择计价类型" allowClear={true} onChange={value => {
            status.businessId = +value;
        }}>
                {status.taskBusinesses.map(taskBusiness => (<Select.Option key={String(taskBusiness.id)} disabled={!taskBusiness.isValid} value={taskBusiness.id}>
                    {taskBusiness.name}
                  </Select.Option>))}
              </Select>
            </Form.Item>
            <Form.Item label={<span className="label-required">优先级</span>}>
              <Radio.Group value={status.level} onChange={e => {
            status.level = e.target.value;
        }}>
                <Radio value={ILabelTask.Level.First}>一级</Radio>
                <Radio value={ILabelTask.Level.Second}>二级</Radio>
                <Radio value={ILabelTask.Level.Third}>三级</Radio>
                <Radio value={ILabelTask.Level.Fourth}>四级</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<span className={[ILabelTask.Level.First, ILabelTask.Level.Second].includes(status.level) ? "label-required" : ""}>
                  任务要求完成日期
                </span>}>
              <DatePicker disabledDate={current => {
            return (current &&
                current <
                    moment()
                        .subtract(1, "day")
                        .endOf("day"));
        }} value={status.taskDeadline} onChange={date => {
            status.taskDeadline = date;
        }}/>
            </Form.Item>
            <Form.Item label="标注要求完成日期">
              <DatePicker disabledDate={current => {
            return ((current &&
                current <
                    moment()
                        .subtract(1, "day")
                        .endOf("day")) ||
                (status.taskDeadline &&
                    current >
                        moment(status.taskDeadline)
                            .clone()
                            .add(1, "day")));
        }} value={status.labelDeadline} onChange={date => {
            status.labelDeadline = date;
        }}/>
            </Form.Item>
            <Form.Item label={<span className="label-required">数据形式</span>}>
              <Radio.Group value={status.resourceMime} onChange={e => {
            status.resourceMime = e.target.value;
            status.taskTemplateId = undefined;
            labelTaskCreateStore.handleChangeTemplate(undefined);
        }}>
                <Radio value={IResource.Mime.Image}>图片</Radio>
                <Radio value={IResource.Mime.Video}>视频</Radio>
                <Radio value={IResource.Mime.Cloud}>点云</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<span className="label-required">任务类型</span>}>
              <Radio.Group value={status.labelTaskType} onChange={e => {
            status.labelTaskType = e.target.value;
            status.taskTemplateId = undefined;
            labelTaskCreateStore.handleChangeTemplate(undefined);
        }}>
                {status.resourceMime === IResource.Mime.Image && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                  </React.Fragment>)}
                {status.resourceMime === IResource.Mime.Video && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Anchor}>时空锚</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Clipping}>切割</Radio>
                  </React.Fragment>)}
                {status.resourceMime === IResource.Mime.Cloud && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                  </React.Fragment>)}
              </Radio.Group>
            </Form.Item>
            <Form.Item label="任务模板">
              <Select showSearch={true} optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="请选择任务模板" allowClear={true} value={status.taskTemplateId} onChange={(value, option) => {
            status.taskTemplateId = value;
            labelTaskCreateStore.handleChangeTemplate(+value);
        }}>
                {status.taskTemplates
            .filter(v => v.taskType == ILabelTask.TaskType.Label)
            .filter(v => v.resourceMime == status.resourceMime)
            .filter(v => v.labelTaskType == status.labelTaskType)
            .map(v => {
            return (<Select.Option key={v.id} value={v.id}>
                        {v.name}
                      </Select.Option>);
        })}
              </Select>
            </Form.Item>
            <Form.Item label={<span className="label-required">标签配置</span>}>
              <LabelEditor ref={ref => (this.editorRef = ref)} defaultLabel={status.defaultEditorLabel} resourceMime={status.resourceMime} labelTaskType={status.labelTaskType} checkIntervalCallback={isPassed => {
            status.isEditorValid = isPassed;
        }}/>
            </Form.Item>
            {status.labelTaskType === ILabelTask.LabelTaskType.Clipping && (<Form.Item label="切割预置时间">
                <Form.Item style={{ display: "inline-block", marginRight: 10 }}>
                  前{" "}
                  <InputNumber value={status.biz.headOffset} step={1} min={0} precision={0} onChange={val => {
            status.biz.headOffset = val;
        }}/>
                  秒
                </Form.Item>
                <Form.Item style={{ display: "inline-block" }}>
                  后{" "}
                  <InputNumber value={status.biz.tailOffset} step={1} min={0} precision={0} onChange={val => {
            status.biz.tailOffset = val;
        }}/>
                  秒
                </Form.Item>
              </Form.Item>)}
            <DataoceanDialog ref={this.dataoceanDialogRef} open={this.state.dataoceanDialogVisible} onCancel={() => {
            labelTaskCreateStore.setStatus({
                resources: [],
                displayResourceUrls: [],
                propertySet: new Set(),
                propertySelected: []
            });
            this.setState({ dataoceanDialogVisible: false });
        }} onSubmit={async (value) => {
            const blobs = value["DATASET_KIND_LABEL_TASK"].concat(value["DATASET_KIND_WORK_BENCH"]);
            status.dataoceanBlobs = blobs.map(blob => blob.meta.name);
            this.setState({
                loading: true,
                dataoceanDialogVisible: false,
                resources: [],
                blobGroup: value
            });
            await this.updateDroplets(blobs);
            this.setState({ loading: false });
            if (status.dataoceanBlobs.length) {
                status.resourceFrom = "dataocean";
            }
        }} width={800} mimeType={{ 0: "image", 1: "video", 4: "cloud" }[status.resourceMime]}></DataoceanDialog>
            <Form.Item label="全量标注">
              <Switch checked={status.needAllLabeled} onChange={checked => (status.needAllLabeled = checked)}></Switch>
              <span style={{ color: "red" }}>只适用于3d分割标注任务</span>
            </Form.Item>
            <Form.Item label={<span className="label-required">数据文件</span>}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload showUploadList={false} beforeUpload={file => {
            labelTaskCreateStore.changeResourceFile(file);
            return false;
        }} accept=".json" disabled={status.resourceFrom === "dataocean"}>
                  <Button disabled={status.resourceFrom === "dataocean"}>
                    <UploadOutlined />
                    上传 jsonlist 文件
                  </Button>
                </Upload>
                <Button disabled={status.resourceFrom === "jsonList"} style={{ marginLeft: 10 }} onClick={() => {
            this.dataoceanDialogRef.current.init();
            this.setState({ dataoceanDialogVisible: true });
        }}>
                  选择 Dataocean 数据
                </Button>
              </div>
              <Checkbox.Group value={toJS(status.propertySelected)} onChange={checkedValue => {
            status.propertySelected = checkedValue;
        }}>
                {status.propertySet.has("url") && (<Checkbox value="url" disabled={true}>
                    URL
                  </Checkbox>)}
                {status.propertySet.has("meta") && <Checkbox value="meta">保留元信息 (meta)</Checkbox>}
                {status.propertySet.has("annotation") && (<Checkbox value="annotation">使用预标注 (annotation)</Checkbox>)}
                {status.propertySet.has("annotation") && <Checkbox value="enableTransform">转换为分割标注</Checkbox>}
              </Checkbox.Group>
              <div>
                {status.fileName && (<div>
                    {status.fileName}&nbsp;
                    <CloseOutlined onClick={() => {
            labelTaskCreateStore.removeResourceFile();
            status.resourceFrom = null;
        }}/>
                  </div>)}
                {Object.keys(this.state.blobGroup).map(key => {
            return (<React.Fragment>
                      {this.state.blobGroup[key].map(blob => {
                return (<Tag closable closeIcon={<CloseOutlined></CloseOutlined>} onClose={() => {
                    this.dataoceanDialogRef.current.onDelete(key, blob);
                    status.resourceFrom = null;
                }}>
                            {blob.meta.name}
                          </Tag>);
            })}
                    </React.Fragment>);
        })}
              </div>
              <Table rowKey={record => record.url} size="small" style={{ marginTop: "8px" }} dataSource={status.displayResourceUrls} columns={[
            {
                title: "URL",
                key: "URL",
                dataIndex: "url",
                ellipsis: true
            }
        ]} loading={status.loading} pagination={{ showSizeChanger: false }}/>
              <p>只展示前 100 条，共 {status.resources.length}条</p>
            </Form.Item>
            <Form.Item label={<span className="label-required">单包资源数目</span>}>
              <InputNumber value={status.perPackageCount} style={{ width: "200px" }} min={1} onChange={value => {
            if (!_.isNaN(+value)) {
                status.perPackageCount = +value;
            }
        }}/>
            </Form.Item>

            {status.resourceMime === IResource.Mime.Image && (<React.Fragment>
                {status.labelTaskType === ILabelTask.LabelTaskType.Segmentation ? (<React.Fragment>
                    <Form.Item label={<span className="label-required">评估方案</span>}>
                      <Radio.Group value={status.taskType} onChange={event => (status.taskType = event.target.value)}>
                        <Radio value={ILabelTask.Type.Normal}>无</Radio>
                        <Radio value={ILabelTask.Type.NewMix}>掺沙</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {status.taskType === ILabelTask.Type.NewMix && (<React.Fragment>
                        <Form.Item label={<span className="label-required">选择沙子包</span>}>
                          <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <Select placeholder="请选择沙子包" style={{ minWidth: "300px" }} allowClear={true} onChange={async (value) => {
            await labelTaskCreateStore.handleChangeSandPackage(+value);
            const { detections, classifications } = this.editorRef.getStatus();
            labelTaskCreateStore.checkIsLabelEqual({
                detections: detections,
                classifications: classifications
            });
        }}>
                              {status.sandBags.map(sandBag => (<Select.Option key={String(sandBag.id)}>{sandBag.name}</Select.Option>))}
                            </Select>
                            <Button style={{ marginLeft: "8px" }} onClick={() => {
            status.isMixLabelModalVisible = true;
        }}>
                              查看标签
                            </Button>
                          </div>
                        </Form.Item>
                        <Form.Item label={<span className="label-required">选择评估方法</span>}>
                          <Select placeholder="请选择评估方法" style={{ width: "300px" }} allowClear={true} onChange={value => {
            status.curMixAssessmentPolicyId = +value;
        }}>
                            {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                {policy.name}
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<span className="label-required">掺沙比例</span>}>
                          <Select placeholder="请选择掺沙比例" style={{ width: "300px" }} onChange={value => {
            status.mixRatio = value;
        }}>
                            {new Array(3).fill(null).map((value, index) => (<Select.Option key={`mix-${index}`} value={(index + 1) * 5}>
                                {(index + 1) * 5}%
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<div>
                              <span className="label-required">评估阈值</span>
                              <Tooltip title="仅支持小写字母、下划线、数字，以小写字母开头（英文半角），不允许有空格">
                                <span className="question">?</span>
                              </Tooltip>
                            </div>}>
                          <div style={{ width: "100%" }}>
                            {status.mixPolicyThresholds.map(threshold => (<PolicyThreshold type={IResource.Mime.Image} key={threshold.uniqKey} item={threshold.configs} name={threshold.nameConfig} segment="2d" add={(key, val) => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 2) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                            <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            if (status.mixPolicyThresholds.length < 1) {
                status.mixPolicyThresholds.push({
                    nameConfig: { value: "log" },
                    uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                    configs: [
                        { key: "box_siou_th", value: undefined },
                        { key: "mask_siou_th", value: undefined }
                    ]
                });
            }
        }}/>
                            <DeleteOutlined onClick={() => {
            status.mixPolicyThresholds.pop();
        }}/>
                          </div>
                        </Form.Item>
                      </React.Fragment>)}
                  </React.Fragment>) : (<React.Fragment>
                    <Form.Item label={<span className="label-required">评估方案</span>}>
                      <Radio.Group value={status.taskType} onChange={event => (status.taskType = event.target.value)}>
                        <Radio value={ILabelTask.Type.Normal}>无</Radio>
                        <Radio value={ILabelTask.Type.NewMix}>掺沙</Radio>
                        <Radio value={ILabelTask.Type.Extract}>专家</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {status.taskType === ILabelTask.Type.NewMix && (<React.Fragment>
                        <Form.Item label={<span className="label-required">选择沙子包</span>}>
                          <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <Select placeholder="请选择沙子包" style={{ minWidth: "300px" }} allowClear={true} onChange={async (value) => {
            await labelTaskCreateStore.handleChangeSandPackage(+value);
            const { detections, classifications } = this.editorRef.getStatus();
            labelTaskCreateStore.checkIsLabelEqual({
                detections: detections,
                classifications: classifications
            });
        }}>
                              {status.sandBags.map(sandBag => (<Select.Option key={String(sandBag.id)}>{sandBag.name}</Select.Option>))}
                            </Select>
                            <Button style={{ marginLeft: "8px" }} onClick={() => {
            status.isMixLabelModalVisible = true;
        }}>
                              查看标签
                            </Button>
                          </div>
                        </Form.Item>
                        <Form.Item label={<span className="label-required">选择评估方法</span>}>
                          <Select placeholder="请选择评估方法" style={{ width: "300px" }} allowClear={true} onChange={value => {
            status.curMixAssessmentPolicyId = +value;
        }}>
                            {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                {policy.name}
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<span className="label-required">掺沙比例</span>}>
                          <Select placeholder="请选择掺沙比例" style={{ width: "300px" }} onChange={value => {
            status.mixRatio = value;
        }}>
                            {new Array(3).fill(null).map((value, index) => (<Select.Option key={`mix-${index}`} value={(index + 1) * 5}>
                                {(index + 1) * 5}%
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<div>
                              <span className="label-required">评估阈值</span>
                              <Tooltip title="仅支持小写字母、下划线、数字，以小写字母开头（英文半角），不允许有空格">
                                <span className="question">?</span>
                              </Tooltip>
                            </div>}>
                          <div style={{ width: "100%" }}>
                            {status.mixPolicyThresholds.map(threshold => {
            return (<PolicyThreshold type={IResource.Mime.Image} key={threshold.uniqKey} name={threshold.nameConfig} item={threshold.configs} segment="none" add={(key, val) => {
                status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                    if (item.uniqKey === threshold.uniqKey) {
                        item.configs.push({ key, value: val });
                    }
                    return item;
                });
            }} minus={() => {
                status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                    var _a;
                    if (item.uniqKey === threshold.uniqKey) {
                        if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                            item.configs.pop();
                        }
                    }
                    return item;
                });
            }}/>);
        })}
                            <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.mixPolicyThresholds.push({
                nameConfig: { value: undefined },
                uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                configs: [
                    { key: "precision", value: undefined },
                    { key: "recall", value: undefined },
                    { key: "iou", value: undefined }
                ]
            });
        }}/>
                            <DeleteOutlined onClick={() => {
            status.mixPolicyThresholds.pop();
        }}/>
                          </div>
                        </Form.Item>
                      </React.Fragment>)}
                    {status.taskType === ILabelTask.Type.Extract && (<React.Fragment>
                        <Form.Item label={<span className="label-required">选择评估方法</span>}>
                          <Select placeholder="请选择评估方法" style={{ width: "300px" }} allowClear={true} onChange={value => {
            status.curExtractAssessmentPolicyId = +value;
        }}>
                            {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                {policy.name}
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<span className="label-required">专家评估比例</span>}>
                          <Select placeholder="请选择专家评估比例" style={{ width: "300px" }} onChange={value => {
            status.extractRatio = value;
        }}>
                            {new Array(3).fill(null).map((value, index) => (<Select.Option key={`extract-${index}`} value={(index + 1) * 5}>
                                {(index + 1) * 5}%
                              </Select.Option>))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={<div>
                              <span className="label-required">评估阈值</span>
                              <Tooltip title="仅支持小写字母、下划线、数字，以小写字母开头（英文半角），不允许有空格">
                                <span className="question">?</span>
                              </Tooltip>
                            </div>}>
                          <div style={{ width: "100%" }}>
                            {status.extractPolicyThresholds.map(threshold => (<PolicyThreshold key={threshold.uniqKey} type={IResource.Mime.Image} item={threshold.configs} name={threshold.nameConfig} segment="none" add={(key, val) => {
            status.extractPolicyThresholds = status.extractPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.extractPolicyThresholds = status.extractPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                            <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.extractPolicyThresholds.push({
                nameConfig: { value: undefined },
                uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                configs: [
                    { key: "precision", value: undefined },
                    { key: "recall", value: undefined },
                    { key: "iou", value: undefined }
                ]
            });
        }}/>
                            <DeleteOutlined onClick={() => {
            status.extractPolicyThresholds.pop();
        }}/>
                          </div>
                        </Form.Item>
                      </React.Fragment>)}
                  </React.Fragment>)}
              </React.Fragment>)}
            {status.resourceMime === IResource.Mime.Video &&
            status.labelTaskType === ILabelTask.LabelTaskType.Detection && (<React.Fragment>
                  <Form.Item label={<span className="label-required">评估方案</span>}>
                    <Radio.Group value={status.taskType} onChange={event => (status.taskType = event.target.value)}>
                      <Radio value={ILabelTask.Type.Normal}>无</Radio>
                      <Radio value={ILabelTask.Type.Extract}>专家</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {status.taskType === ILabelTask.Type.Extract && (<React.Fragment>
                      <Form.Item label={<span className="label-required">选择评估方法</span>}>
                        <Select placeholder="请选择评估方法" style={{ width: "300px" }} allowClear={true} onChange={value => {
            status.curExtractAssessmentPolicyId = +value;
        }}>
                          {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                              {policy.name}
                            </Select.Option>))}
                        </Select>
                      </Form.Item>
                      <Form.Item label={<div>
                            <span className="label-required">评估阈值</span>
                            <Tooltip title="仅支持小写字母、下划线、数字，以小写字母开头（英文半角），不允许有空格">
                              <span className="question">?</span>
                            </Tooltip>
                          </div>}>
                        <div style={{ width: "100%" }}>
                          {status.videoExtractPolicyThresholds.map(threshold => (<PolicyThreshold type={IResource.Mime.Video} key={threshold.uniqKey} videoItem={threshold.configs} name={threshold.nameConfig} segment="none" add={(key, val) => {
            status.videoExtractPolicyThresholds = status.videoExtractPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.videoExtractPolicyThresholds = status.videoExtractPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                          <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.videoExtractPolicyThresholds.push({
                nameConfig: { value: undefined },
                uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                configs: [
                    { key: "mota", value: undefined },
                    { key: "motp", value: undefined },
                    { key: "mt", value: undefined }
                ]
            });
        }}/>
                          <DeleteOutlined onClick={() => {
            status.videoExtractPolicyThresholds.pop();
        }}/>
                        </div>
                      </Form.Item>
                    </React.Fragment>)}
                </React.Fragment>)}
            <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <Button onClick={() => {
            routerStore.goBack();
        }}>
                  取消
                </Button>
                <Button style={{ marginLeft: "10px" }} loading={status.isCreatingLabelTask} disabled={!labelTaskCreateStore.isEnableCreate} onClick={() => {
            const { mixPolicyThresholds, extractPolicyThresholds, level, taskDeadline, labelDeadline, labelTaskType } = status;
            const { detections, classifications, anchors, segments, segments3D, detections3D } = this.editorRef.getStatus();
            const curLabel = {
                detections: detections,
                classifications: classifications,
                anchors,
                segments,
                segments3D,
                detections3D
            };
            if ([ILabelTask.Level.First, ILabelTask.Level.Second].includes(level) && _.isNil(taskDeadline)) {
                message.warning("任务要求完成日期不能为空");
                return;
            }
            if (taskDeadline && labelDeadline && moment(taskDeadline).isBefore(moment(labelDeadline))) {
                message.warning("标注要求完成日期须在任务要求完成日期之前");
                return;
            }
            if (!detections.length &&
                !classifications.length &&
                !anchors.length &&
                !segments.length &&
                !segments3D.length &&
                !detections3D.length) {
                message.warning("标签配置不能为空");
                return;
            }
            if (labelTaskType == null) {
                message.warning("请选择任务类型");
                return;
            }
            if (status.taskType === ILabelTask.Type.NewMix) {
                if (!segments.length) {
                    const isEqual = labelTaskCreateStore.checkIsLabelEqual(curLabel);
                    if (!isEqual) {
                        message.warning("当前任务标签与沙子标签不一致");
                        return;
                    }
                    for (let i = 0, l = mixPolicyThresholds.length; i < l; i++) {
                        const _a = mixPolicyThresholds[i], { uniqKey, nameConfig } = _a, rest = __rest(_a, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const data = _.zipObject(keys, values);
                        if (_.isNil(nameConfig.value) || _.isNil(data.precision) || _.isNil(data.recall)) {
                            message.warning("评估阈值不能存在空值");
                            return;
                        }
                    }
                }
            }
            if (status.taskType === ILabelTask.Type.Extract) {
                for (let i = 0, l = extractPolicyThresholds.length; i < l; i++) {
                    const _b = extractPolicyThresholds[i], { uniqKey, nameConfig } = _b, rest = __rest(_b, ["uniqKey", "nameConfig"]);
                    const keys = rest.configs.map(v => v.key);
                    const values = rest.configs.map(v => v.value);
                    const data = _.zipObject(keys, values);
                    if (_.isNil(nameConfig.value) || _.isNil(data.precision) || _.isNil(data.recall)) {
                        message.warning("评估阈值不能存在空值");
                        return;
                    }
                }
            }
            const resources = labelTaskCreateStore.status.resources;
            for (let i = 0; i < resources.length; i++) {
                let annotation = resources[i].annotation;
                if (labelTaskCreateStore.status.propertySelected && annotation) {
                    if (!labelTaskCreateStore.validateAnnotation(annotation)) {
                        message.warning("标注信息不能存在空值");
                        return;
                    }
                }
            }
            labelTaskCreateStore.status.isCreatingLabelTask = true;
            labelTaskCreateStore.createLabelTask(curLabel);
        }}>
                  创建
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <React.Fragment>
          <SandPackageLabelModal visible={status.curMixSandBag && status.isMixLabelModalVisible} onCancel={() => {
            status.isMixLabelModalVisible = false;
        }} label={(_a = status.curMixSandBag) === null || _a === void 0 ? void 0 : _a.label}/>
        </React.Fragment>
      </PageHeader>);
    }
};
LabelTaskCreate = __decorate([
    observer
], LabelTaskCreate);
export { LabelTaskCreate };
const loadMediaInfo = (() => {
    let mediaInfo = null;
    return async () => {
        if (!mediaInfo) {
            mediaInfo = MediaInfo();
        }
        return mediaInfo;
    };
})();
loadMediaInfo();
async function getVideoMetaData(url) {
    const mediaInfo = await loadMediaInfo();
    const mediaInfoHelper = new MediaInfoHelper(url);
    const data = await mediaInfo.analyzeData(() => {
        return mediaInfoHelper.getSize();
    }, (size, offset) => {
        return mediaInfoHelper.getChunk(size, offset);
    });
    return data;
}
function splitFirstDash(str) {
    const index = str.indexOf("-");
    if (index !== -1) {
        const part1 = str.slice(0, index);
        const part2 = str.slice(index + 1);
        return [part1, part2];
    }
    else {
        return [str];
    }
}
